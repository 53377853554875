.custom-timepicker{
    width:170px;
    color:orange;
    background:#202940;
    margin-top:14px;
    border-radius: 10px;
    font-size: 25px;

}

.error {
    border-color: red;
  }


  .react-time-picker__amPm {
    background-color: #120538; /* Set your desired background color */
    color: #333; /* Set text color */
  }